<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <div class="pr-2 pl-2">
        <b-row class="d-flex justify-content-between">
          <b-col
            cols="12"
            md="5"
            class="d-flex align-items-center justify-content-start mt-1 mb-1"
          >
            <div>
              <b-form-group
                label="Exibir Linhas"
                label-for="idPerPage"
                class="mb-0"
              >
                <v-select
                  id="idPerPage"
                  v-model="pageSize"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block"
                >
                  <span slot="no-options">Não há opções para essa quantidade.</span>
                </v-select>
              </b-form-group>
            </div>
            <div class="ml-1 w-100">
              <b-form-group
                label="Buscar na Tabela"
                label-for="idSearchBox"
                class="mb-0"
              >
                <b-form-input
                  id="idSearchBox"
                  v-model.lazy="filterOptions.search"
                  placeholder="Buscar"
                  type="text"
                  class="d-inline-block"
                />
              </b-form-group>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  VBTooltip,
} from 'bootstrap-vue'

import {
  mdiTableEdit,
  mdiFilterPlusOutline,
  mdiCircle,
  mdiCircleOutline,
  mdiTrashCanOutline,
  mdiFileExportOutline,
} from '@mdi/js'

import {
  useToast,
} from 'vue-toastification/composition'

import moment from 'moment'
import vSelect from 'vue-select'
import formValidation from '@core/comp-functions/forms/form-validation'
import truncate from '@/components/utils/filters/truncate'
import SyncLoad from '@/components/utils/syncLoad'
import MetaData from '@/components/utils/metaData'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  filters: {
    truncate,
  },
  props: {
    perPage: {
      type: Number,
      required: false,
      default: 10,
    },
    perPageOptions: {
      type: Array,
      required: false,
      default: () => [10, 25, 50, 100],
    },
    visibleColumns: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  emits: ['ready'],

  data() {
    const filterList = []

    const filterEmpty = {
      page_size: this.perPage,
      search: '',
    }

    const filterOptions = JSON.parse(JSON.stringify(filterEmpty))

    const tableColumns = []

    const isLoading = true
    const syncLoad = new SyncLoad()
    syncLoad.addTrigger('filter')

    syncLoad.onReady(() => {
      this.isLoading = false
      this.$emit('ready', true)
    })

    const {
      refFormObserver,
      getValidationState,
    } = formValidation(this.value)

    const meta = new MetaData('ProfileFilter')

    return {
      refFormObserver,
      getValidationState,
      filterEmpty,
      filterOptions,
      filterList,
      tableColumns,
      syncLoad,
      isLoading,
      meta,
    }
  },
  computed: {
    pageSize: {
      get() {
        return this.filterOptions.page_size
      },
      set(value) {
        this.filterOptions.page_size = value
        this.refreshFilter()
      },
    },
    pageSearch: {
      get() {
        return this.filterOptions.search
      },
      set(value) {
        this.filterOptions.search = value
        this.refreshFilter()
      },
    },
    ajustableFields() {
      const fields = []
      this.tableColumns.forEach((element, index) => {
        if (!element.fixedColumn) {
          fields.push({
            index,
            label: element.label,
          })
        }
      })

      return fields
    },
  },
  watch: {
    tableColumns: {
      handler: function updateColumns(val) {
        this.$emit('columns-change', val)
      },
      deep: true,
    },
    filterOptions: {
      handler: function updateFilter() {
        this.refreshFilter()
      },
      deep: true,
    },
  },
  created() {
    this.tableColumns = this.visibleColumns

    this.filterOptions = this.meta.bind(this.filterOptions, 'filterOptions')
    this.tableColumns = this.meta.bind(this.tableColumns, 'tableColumns')
  },
  methods: {
    refreshFilter() {
      const filter = this.getFilter(this.filterOptions)
      this.$emit('filter-change', filter)
    },
    getFilter(val) {
      const filter = {
        page_size: val.page_size,
        search: val.search,
      }

      return filter
    },
    selectFilter(index) {
      const { page_size, search } = this.filterOptions

      this.filterOptions = JSON.parse(JSON.stringify(this.filterList[index]))

      /* eslint camelcase: ["off"] */
      this.filterOptions.page_size = page_size
      this.filterOptions.search = search
    },
  },
  setup() {
    const toast = useToast()
    return {
      toast,
      moment,
      mdiTableEdit,
      mdiFilterPlusOutline,
      mdiCircle,
      mdiCircleOutline,
      mdiTrashCanOutline,
      mdiFileExportOutline,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.filterOptionsForm {
  background-color: #FAFAFC;
  border-top: 1px solid #d8d6de;
}

.filterBtn {
  min-width: 7.5rem;
}

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#idDropdownSelectColumns .btn.dropdown-toggle {
  padding: 0px !important;
}

#idBtnDropdownFilter {
  min-width: 20rem;
}

#idModalFilter .modal-footer {
  display: flex !important;
  flex-direction: row-reverse;
  justify-content: end;

  .btn{
    min-width: 107px;
  }
}

.buttonsSaveCancel{
  .btn{
    min-width: 107px;
  }
}
</style>
